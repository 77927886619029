<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start pb-0">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">รายการปรับปรุงสต๊อก</h4>
      </div>

      <div class="d-flex flex-row align-end justify-start mb-1">
        <v-stepper
          v-model="e6"
          vertical
          class="border-inform-visit"
        >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
          >
            <div class="d-flex flex-row align-center">
              <v-icon class="mr-3">mdi-pill</v-icon>
              ยา
            </div>
            <small>เลือกยา</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-data-table
              :loading="loaddatadrug"
              loading-text="กำลังโหลด..."
              v-model="selectedDrug"
              :headers="headersDrug"
              :items="dataDrug"
              :single-select="singleSelectDrug"
              item-key="_id"
              show-select
            >
            <template v-slot:top>
            </template>
            <template v-slot:item.drug_name="{ item }">
              <div class="text-start">{{item.drug_name}}</div>
            </template>
            <template v-slot:item.drug_costprice="{ item }">
              <div class="text-end">{{formatThaiBaht(item.drug_costprice)}}</div>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initDataDrug"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            <v-btn
              color="primary"
              @click="e6 = 2"
            >
              ถัดไป
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
          >
            <div class="d-flex flex-row align-center">
              <v-icon class="mr-3">mdi-needle</v-icon>
              เวชภัณฑ์
            </div>
            <small>เลือกเวชภัณฑ์</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-data-table
              :loading="loaddatamedical"
              loading-text="กำลังโหลด..."
              v-model="selectedMedical"
              :headers="headersMedical"
              :items="dataMedical"
              :single-select="singleSelectMedical"
              item-key="_id"
              show-select
            >
            <template v-slot:top>
            </template>
            <template v-slot:item.medical_name="{ item }">
              <div class="text-start">{{item.medical_name}}</div>
            </template>
            <template v-slot:item.medical_costprice="{ item }">
              <div class="text-end">{{formatThaiBaht(item.medical_costprice)}}</div>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initDataMedical"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            <v-btn
              color="primary"
              class="mr-3"
              @click="e6 = 3"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 1"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 3"
            step="3"
          >
          สร้างรายการปรับปรุงสต๊อก
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-data-table
              :loading="loaddata"
              loading-text="กำลังโหลด..."
              :headers="headers"
              :items="data"
            >
            <template v-slot:item.stock_bestbefore="{ item }">
              <div class="text-center">{{moment(item.stock_bestbefore).add(543, 'year').format('DD/MM/YYYY')}}</div>
            </template>
            <template v-slot:item.stock_remain="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_remain)}}</div>
            </template>
            <template v-slot:item.stock_costprice="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_costprice)}}</div>
            </template>
            <template v-slot:item.stock_price="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_price)}}</div>
            </template>
            <template v-slot:item.stock_actions="{ item }">
              <div class="">
                <v-select
                  :items="itemStockActions"
                  v-model="item.stock_actions"
                  item-text="name"
                  item-value="name"
                  label=""
                  placeholder="เลืือกเพิ่ม/ลด"
                  no-data-text="ยังไม่มีข้อมูล"
                  @change="detectActions"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </div>
            </template>
            <template v-slot:item.stock_amount="{ item }">
              <div class="">
                <v-text-field
                  label=""
                  v-model="item.stock_amount"
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </template>
            <template v-slot:item.stock_total="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_price * item.stock_amount)}}</div>
            </template>
            <template v-slot:item.stock_delete="{ item }">
              <v-btn
                icon
                @click="deleteLineList(item)"
              >
                <v-icon large>mdi-minus-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="e6 = 1"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span>สาเหตุในการปรับปรุงจำนวนสต๊อก</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-text-field
                  label=""
                  v-model="inputCause"
                  outlined
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span>ผู้บันทึกการปรับปรุงสต๊อก</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <v-select
                  :items="itemsCreateBy"
                  v-model="selectCreateBy"
                  item-text="name"
                  item-value="name"
                  label=""
                  placeholder="เลืือกผู้บันทึกการปรับปรุงสต๊อก"
                  no-data-text="ยังไม่มีข้อมูล"
                  @change="detectActions"
                  outlined
                  single-line
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              class="mr-3"
              :disabled="cansavetocreate"
              @click="e6 = 4"
            >
              ถัดไป
            </v-btn>
            <v-btn
              text
              @click="e6 = 2"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 4"
            step="4"
          >
          รายการปรับปรุงสต๊อก
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-data-table
              :loading="loaddata"
              loading-text="กำลังโหลด..."
              :headers="headersCreate"
              :items="data"
            >
              <template v-slot:item.stock_name="{ item }">
                <div class="text-start">{{item.stock_name}}</div>
              </template>
              <template v-slot:item.stock_bestbefore="{ item }">
                <div class="text-center">{{moment(item.stock_bestbefore).add(543, 'year').format('DD/MM/YYYY')}}</div>
              </template>
              <template v-slot:item.stock_costprice="{ item }">
                <div class="text-end">{{formatThaiBaht(item.stock_costprice)}}</div>
              </template>
              <template v-slot:item.stock_price="{ item }">
                <div class="text-end">{{formatThaiBaht(item.stock_price)}}</div>
              </template>
              <template v-slot:item.stock_amount="{ item }">
                <div class="text-end">{{formatThaiBaht(item.stock_amount)}}</div>
              </template>
              <template v-slot:item.stock_delete="{ item }">
                <v-btn
                  icon
                  @click="deleteLineList(item)"
                >
                  <v-icon large>mdi-minus-circle</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="e6 = 1"
                >
                  รีเซ็ต
                </v-btn>
              </template>
            </v-data-table>
            <v-btn
              :loading="loadingCreate"
              :disabled="loadingCreate"
              color="success"
              class="mr-3"
              @click="submitCreate"
            >
              <v-icon
                left
                dark
              >
                mdi-content-save
              </v-icon>
              สร้างรายการปรับปรุงสต๊อก
            </v-btn>
            <v-btn
              :loading="loadingCreate"
              :disabled="loadingCreate"
              color="error"
              class="mr-3"
              @click="cancelCreate"
            >
              <v-icon
                left
                dark
              >
                mdi-close-circle
              </v-icon>
              ยกเลิกรายการ
            </v-btn>
            <v-btn
              text
              @click="e6 = 3"
            >
              ย้อนกลับ
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'

export default {
  data () {
    return {
      e6: 1,
      inputToday: moment().utc,
      loaddatadrug: false,
      singleSelectDrug: false,
      selectedDrug: [],
      dataDrug: [{
        _id: '0001',
        drug_course_no: 'M0001',
        drug_name: 'ยาพารา',
        drug_amount: 1,
        drug_unit: 'กล่อง',
        drug_costprice: 50
      }, {
        _id: '0002',
        drug_course_no: 'M0002',
        drug_name: 'ยาฆ่าเชื้อ',
        drug_amount: 1,
        drug_unit: 'กล่อง',
        drug_costprice: 70
      }, {
        _id: '0003',
        drug_course_no: 'M0003',
        drug_name: 'ยาลดบวม',
        drug_amount: 1,
        drug_unit: 'กล่อง',
        drug_costprice: 60
      }, {
        _id: '0004',
        drug_course_no: 'M0004',
        drug_name: 'ยาแก้อักเสบ',
        drug_amount: 1,
        drug_unit: 'กล่อง',
        drug_costprice: 60
      }, {
        _id: '0005',
        drug_course_no: 'M0005',
        drug_name: 'ยาแก้แพ้',
        drug_amount: 1,
        drug_unit: 'กล่อง',
        drug_costprice: 40
      }],
      loaddatamedical: false,
      singleSelectMedical: false,
      selectedMedical: [],
      dataMedical: [{
        _id: '0001',
        medical_course_no: 'E0001',
        medical_name: 'เข็มทู่สำหรับร้อยไหมคอลลาเจน',
        medical_amount: 1,
        medical_unit: 'อัน',
        medical_costprice: 25
      }, {
        _id: '0002',
        medical_course_no: 'E0002',
        medical_name: 'เข็มทู่สำหรับร้อยไหมทอนาโด',
        medical_amount: 1,
        medical_unit: 'อัน',
        medical_costprice: 50
      }, {
        _id: '0003',
        medical_course_no: 'E0003',
        medical_name: 'เข็มทู่สำหรับฉีดฟิลเลอร์ใต้ตา',
        medical_amount: 1,
        medical_unit: 'อัน',
        medical_costprice: 150
      }, {
        _id: '0004',
        medical_course_no: 'E0004',
        medical_name: 'น้ำเกลือ',
        medical_amount: 1,
        medical_unit: 'อัน',
        medical_costprice: 25
      }, {
        _id: '0005',
        medical_course_no: 'E0005',
        medical_name: 'สำลี',
        medical_amount: 1,
        medical_unit: 'กล่อง',
        medical_costprice: 10
      }],
      loaddata: false,
      data: [],
      loadingCreate: false,
      loaderCreate: null,
      lineno: 0,
      itemStockActions: [{
        _id: '00',
        name: 'เพิ่ม'
      }, {
        _id: '01',
        name: 'ลด'
      }],
      selectLineDel: null,
      itemsCreateBy: [{
        _id: '00000000000',
        name: 'Admin_WG001'
      }, {
        _id: '00000000001',
        name: 'Admin_WG002'
      }, {
        _id: '00000000003',
        name: 'Admin_WG003'
      }],
      inputCause: '',
      selectCreateBy: 'Admin_WG001',
      cansavetocreate: true
    }
  },
  computed: {
    headersDrug () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          sortable: false,
          value: 'drug_course_no'
        }, {
          text: 'ชื่อยา',
          align: 'center',
          value: 'drug_name',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'drug_amount',
          sortable: false
        }, {
          text: 'หน่วย',
          align: 'center',
          value: 'drug_unit',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'drug_costprice',
          sortable: false
        }
      ]
    },
    headersMedical () {
      return [
        {
          text: 'รหัสคอร์ส',
          align: 'center',
          sortable: false,
          value: 'medical_course_no'
        }, {
          text: 'ชื่ออุปกรณ์',
          align: 'center',
          value: 'medical_name',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'medical_amount',
          sortable: false
        }, {
          text: 'หน่วย',
          align: 'center',
          value: 'medical_unit',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'medical_costprice',
          sortable: false
        }
      ]
    },
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'stock_lineno'
        },
        {
          text: 'รอบสต๊อก',
          align: 'center',
          sortable: false,
          value: 'stock_stockcycle'
        }, {
          text: 'วันที่หมดอายุ',
          align: 'center',
          value: 'stock_bestbefore',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'stock_remain',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'stock_costprice',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'stock_price',
          sortable: false
        }, {
          text: 'เพิ่ม/ลด',
          align: 'center',
          value: 'stock_actions',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'stock_amount',
          sortable: false
        }, {
          text: 'หน่วย',
          align: 'center',
          value: 'stock_unit',
          sortable: false
        }, {
          text: 'รวม',
          align: 'center',
          value: 'stock_total',
          sortable: false
        }, {
          text: 'ลบ',
          align: 'center',
          value: 'stock_delete',
          sortable: false
        }
      ]
    },
    headersCreate () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'stock_lineno'
        }, {
          text: 'รายการ',
          align: 'center',
          sortable: false,
          value: 'stock_name'
        }, {
          text: 'รอบสต๊อก',
          align: 'center',
          sortable: false,
          value: 'stock_stockcycle'
        }, {
          text: 'หมดอายุ',
          align: 'center',
          value: 'stock_bestbefore',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'stock_costprice',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'stock_price',
          sortable: false
        }, {
          text: '(เพิ่ม/ลด)',
          align: 'center',
          value: 'stock_actions',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'stock_amount',
          sortable: false
        }
      ]
    }
  },
  watch: {
    selectedDrug () {
      const self = this
      self.initData()
    },
    selectedMedical () {
      const self = this
      self.initData()
    }
  },
  methods: {
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    initDataDrug () {
      const self = this
      self.loaddatadrug = true
      setTimeout(() => {
        self.loaddatadrug = false
      }, 200)
    },
    initDataMedical () {
      const self = this
      self.loaddatamedical = true
      setTimeout(() => {
        self.loaddatamedical = false
      }, 200)
    },
    initData () {
      const self = this
      self.loaddata = true
      self.data = []
      var temp = []
      self.selectedDrug.map((el, index) => {
        temp.push({
          _id: index,
          stock_select_drug_id: el._id,
          stock_lineno: index + 1,
          stock_name: el.drug_name,
          stock_stockcycle: `LT00${index + 1}`,
          stock_bestbefore: moment(),
          stock_remain: 3,
          stock_costprice: el.drug_costprice,
          stock_price: 1,
          stock_actions: '',
          stock_amount: el.drug_amount,
          stock_unit: el.drug_unit,
          stock_total: 0
        })
      })
      self.selectedMedical.map((el, index) => {
        temp.push({
          _id: self.selectedDrug.length + index,
          stock_select_medical_id: el._id,
          stock_lineno: self.selectedDrug.length + index + 1,
          stock_name: el.medical_name,
          stock_stockcycle: `LT00${self.selectedDrug.length + index + 1}`,
          stock_bestbefore: moment(),
          stock_remain: 3,
          stock_costprice: el.medical_costprice,
          stock_price: 1,
          stock_actions: '',
          stock_amount: el.medical_amount,
          stock_unit: el.medical_unit,
          stock_total: 0
        })
      })
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 200)
    },
    detectActions () {
      const self = this
      self.data.forEach((ele) => {
        if (ele.stock_actions) self.cansavetocreate = false
        else if (!ele.stock_actions) self.cansavetocreate = true
      })
    },
    deleteLineList (item) {
      const self = this
      var stockdrugindex = null
      var stockmedicalindex = null
      if (item.stock_select_drug_id) {
        stockdrugindex = self.selectedDrug.findIndex(el => {
          return el._id === item.stock_select_drug_id
        })
      } else if (item.stock_select_medical_id) {
        stockmedicalindex = self.selectedMedical.findIndex(el => {
          return el._id === item.stock_select_medical_id
        })
      }

      swal(`ต้องการลบรายการ ${item.stock_name} รอบสต๊อก ${item.stock_stockcycle} ในแถวที่ ${item.stock_lineno} ใช่หรือไม่`, {
        dangerMode: true,
        buttons: {
          cancel: 'ไม่ใช่',
          confirm: {
            text: 'ใช่',
            value: 'confirm'
          }
        }
      }).then((value) => {
        if (value === 'confirm') {
          swal('สำเร็จ', 'ลบข้อมูลสำเร็จ', 'success', {
            button: false,
            timer: 2000
          }).then(() => {
            self.data.splice(item._id, 1)
            if (item.stock_select_drug_id) self.selectedDrug.splice(stockdrugindex, 1)
            else if (item.stock_select_medical_id) self.selectedMedical.splice(stockmedicalindex, 1)
            self.initData()
          })
        } else {
          swal('คำเตือน', 'ยังไม่ได้ลบข้อมูล', 'warning', {
            button: false,
            timer: 3000
          })
        }
      })
      self.selectLineDel = item
    },
    submitCreate () {
      const self = this
      self.loaderCreate = 'loadingCreate'
      self.loadingCreate = true
      setTimeout(() => {
        self.loadingCreate = false
        self.e6 = 4
      }, 200)
    },
    cancelCreate () {
      const self = this
      self.loaderCreate = 'loadingCreate'
      self.loadingCreate = true
      setTimeout(() => {
        self.loadingCreate = false
        self.e6 = 1
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Customer.css';

.v-stepper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: none !important;
}

.v-stepper--vertical .v-stepper__step {
  padding: 24px 0 16px 0 !important;
}

.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 12px !important;
}
</style>
